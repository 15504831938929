/* kanit-100normal - all */
@font-face {
  font-family: 'Kanit';
  font-style: normal;
  font-display: swap;
  font-weight: 100;
  src:
    local('Kanit Thin'),
    local('Kanit-Thin'), 
    url('./files/kanit-all-100.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./files/kanit-all-100.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* kanit-100italic - all */
@font-face {
  font-family: 'Kanit';
  font-style: italic;
  font-display: swap;
  font-weight: 100;
  src:
    local('Kanit Thin Italic'),
    local('Kanit-ThinItalic'), 
    url('./files/kanit-all-100-italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./files/kanit-all-100-italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* kanit-200normal - all */
@font-face {
  font-family: 'Kanit';
  font-style: normal;
  font-display: swap;
  font-weight: 200;
  src:
    local('Kanit ExtraLight'),
    local('Kanit-ExtraLight'), 
    url('./files/kanit-all-200.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./files/kanit-all-200.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* kanit-200italic - all */
@font-face {
  font-family: 'Kanit';
  font-style: italic;
  font-display: swap;
  font-weight: 200;
  src:
    local('Kanit ExtraLight Italic'),
    local('Kanit-ExtraLightItalic'), 
    url('./files/kanit-all-200-italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./files/kanit-all-200-italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* kanit-300normal - all */
@font-face {
  font-family: 'Kanit';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src:
    local('Kanit Light'),
    local('Kanit-Light'), 
    url('./files/kanit-all-300.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./files/kanit-all-300.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* kanit-300italic - all */
@font-face {
  font-family: 'Kanit';
  font-style: italic;
  font-display: swap;
  font-weight: 300;
  src:
    local('Kanit Light Italic'),
    local('Kanit-LightItalic'), 
    url('./files/kanit-all-300-italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./files/kanit-all-300-italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* kanit-400normal - all */
@font-face {
  font-family: 'Kanit';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src:
    local('Kanit Regular'),
    local('Kanit-Regular'), 
    url('./files/kanit-all-400.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./files/kanit-all-400.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* kanit-400italic - all */
@font-face {
  font-family: 'Kanit';
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src:
    local('Kanit Italic'),
    local('Kanit-Italic'), 
    url('./files/kanit-all-400-italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./files/kanit-all-400-italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* kanit-500normal - all */
@font-face {
  font-family: 'Kanit';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src:
    local('Kanit Medium'),
    local('Kanit-Medium'), 
    url('./files/kanit-all-500.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./files/kanit-all-500.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* kanit-500italic - all */
@font-face {
  font-family: 'Kanit';
  font-style: italic;
  font-display: swap;
  font-weight: 500;
  src:
    local('Kanit Medium Italic'),
    local('Kanit-MediumItalic'), 
    url('./files/kanit-all-500-italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./files/kanit-all-500-italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* kanit-600normal - all */
@font-face {
  font-family: 'Kanit';
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src:
    local('Kanit SemiBold'),
    local('Kanit-SemiBold'), 
    url('./files/kanit-all-600.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./files/kanit-all-600.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* kanit-600italic - all */
@font-face {
  font-family: 'Kanit';
  font-style: italic;
  font-display: swap;
  font-weight: 600;
  src:
    local('Kanit SemiBold Italic'),
    local('Kanit-SemiBoldItalic'), 
    url('./files/kanit-all-600-italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./files/kanit-all-600-italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* kanit-700normal - all */
@font-face {
  font-family: 'Kanit';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src:
    local('Kanit Bold'),
    local('Kanit-Bold'), 
    url('./files/kanit-all-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./files/kanit-all-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* kanit-700italic - all */
@font-face {
  font-family: 'Kanit';
  font-style: italic;
  font-display: swap;
  font-weight: 700;
  src:
    local('Kanit Bold Italic'),
    local('Kanit-BoldItalic'), 
    url('./files/kanit-all-700-italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./files/kanit-all-700-italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* kanit-800normal - all */
@font-face {
  font-family: 'Kanit';
  font-style: normal;
  font-display: swap;
  font-weight: 800;
  src:
    local('Kanit ExtraBold'),
    local('Kanit-ExtraBold'), 
    url('./files/kanit-all-800.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./files/kanit-all-800.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* kanit-800italic - all */
@font-face {
  font-family: 'Kanit';
  font-style: italic;
  font-display: swap;
  font-weight: 800;
  src:
    local('Kanit ExtraBold Italic'),
    local('Kanit-ExtraBoldItalic'), 
    url('./files/kanit-all-800-italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./files/kanit-all-800-italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* kanit-900normal - all */
@font-face {
  font-family: 'Kanit';
  font-style: normal;
  font-display: swap;
  font-weight: 900;
  src:
    local('Kanit Black'),
    local('Kanit-Black'), 
    url('./files/kanit-all-900.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./files/kanit-all-900.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* kanit-900italic - all */
@font-face {
  font-family: 'Kanit';
  font-style: italic;
  font-display: swap;
  font-weight: 900;
  src:
    local('Kanit Black Italic'),
    local('Kanit-BlackItalic'), 
    url('./files/kanit-all-900-italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./files/kanit-all-900-italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
